<template>
  <div class="login">
    <div class="login-top">
      <div class="login-top-logo">
        <img src="../assets/img/logo.png" alt="logo">
      </div>
      <h2>华医体检电子报告</h2>
      <h3>在线查询入口</h3>
    </div>
    <div class="login-form">
      <div class="login-form-item">
        <i class="icon icon-user"></i>
        <input v-model.trim="id_card" type="text" placeholder="请输入身份证号">
      </div>
      <div class="login-form-item">
        <i class="icon icon-phone"></i>
        <input v-model.trim="mobile_phone" type="text" placeholder="请输入手机号码">
      </div>
      <div class="login-form-item three">
        <i class="icon icon-shield"></i>
        <input v-model.trim="sms_code" type="text" class="small" placeholder="请输入验证码">
        <button class="login-btn min" @click="handleSms">验证码</button>
      </div>
      <button class="login-btn" @click="handleClick">登 录</button>
    </div>
  </div>
</template>

<script>
import api from '../api'
import { mapMutations } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      mobile_phone: '15315178061',
      sms_code: '1',
      id_card: '370802198001250324',
      loading: false
    }
  },
  methods: {
    ...mapMutations(['saveData']),
    handleClick () {
      if (this.loading) return
      if (this.id_card == '') {
        alert('请输入身份证号')
        return
      }
      if (this.mobile_phone == '') {
        alert('请输入手机号')
        return
      }
      // if (this.sms_code == '') {
      //   alert('请输入验证码')
      //   return
      // }
      this.loading = true
      api.post('report/login', {
        id_card: this.id_card,
        mobile_phone: this.mobile_phone,
        sms_code: this.sms_code
      }).then(res => {
        if (res) {
          this.saveData(res.data)
          this.$router.push('/ae')
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 发送短信验证码
    handleSms () {
      if (!this.mobile_phone) {
        alert('请输入手机号')
        return
      }
      api.post('report/login_sms', { mobile_phone: this.mobile_phone }).then(res => {
        if (res) {
          alert('验证码发送成功')
        }
      })
    }
  }
}
</script>

<style>

</style>